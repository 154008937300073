import React, { useState } from "react";
import { Modal, Spin, Tag } from "antd";
import { TASK_STATUS } from "../constant";
import { StyledCsrTaskModal } from "./StyledCsrTaskModal";
import { USER_ROLE } from "../../../constants/userRole";
import { CsrTaskService } from "../../../Services/CsrTaskService";
import toast from "react-hot-toast";
import { getFormattedDate } from "../../../utils/timeUtils";
import { Form, Input } from "antd";

const { TextArea } = Input;

const CsrTaskModal = ({ isVisible, onCancel, task, userRole, onSuccess = () => { } }) => {

  const [loading, setLoading] = useState(false);
  const [remark,setRemark] = useState('');

  const handleButtonClick = async (e, status) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      setLoading(true);
      const body = {
        'status': status,
        'remark':remark
      }
      const markCompleted = await CsrTaskService.updateCsrTaskStatus(task?.task_detail?.task_id, body);
      toast.success(`Marked as ${status?.replace("_", " ").toLowerCase()} successfully`);
      onSuccess();
      onCancel();
    }
    catch (e) {
      toast.error("Something went wrong")
    }
    finally {
      setLoading(false)
    }
  };

  const getColorForStatus = (status) => {
    if(status===TASK_STATUS.PENDING) return 'magenta';
    if(status === TASK_STATUS.IN_PROGRESS) return 'processing';
    return 'success';
  }

  const isNonCompleted = task?.task_status === TASK_STATUS.PENDING || task?.task_status === TASK_STATUS.IN_PROGRESS ;

  return (
    <Modal
      title="Task Details"
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
    >
      <Spin spinning={loading}>
      <StyledCsrTaskModal>
        {task?.task_status && <Tag color={getColorForStatus(task?.task_status)} className="status-tag">{task?.task_status?.replace('_',' ')}</Tag>}
        <div className="modal-wrapper">
          <div className="task-info">
            <h3>{task?.task_detail?.title}</h3>
            <div dangerouslySetInnerHTML={{ __html:task?.task_detail?.content}}></div>
            {isNonCompleted && (
              <div className="due-date-wrapper">
                <p className="due-date">
                  Due Date:{" "}
                  {
                    getFormattedDate(task?.task_detail?.due_date)
                  }
                </p>
              </div>
            )}
            <p className="tags">
              Tags:{" "}
              {task?.task_detail?.tags
                .join(", ")
                .replaceAll("_", " ")
                .toLowerCase()}
            </p>
            <p className="remarks">
              Created by : {task?.created_by?.first_name}
            </p>
            { 
            task?.task_status === TASK_STATUS.COMPLETED &&
              <p className="remarks">Closing Remark: <span >{task?.task_detail?.remark} </span></p>
            }
            {task?.task_status === TASK_STATUS.IN_PROGRESS && task?.task_detail?.remark &&
              <p className="remarks">Progress Remark: <span >{task?.task_detail?.remark} </span></p>
            }
          </div>
          {isNonCompleted && (userRole === USER_ROLE.OPERATION_EXECUTIVE || userRole === USER_ROLE.ADMIN)  && (
            <div>
              <Form.Item label="" required>
                <TextArea
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  placeholder="Enter Remark"
                  rows={2}
                  required
                />
              </Form.Item>
              <div className='buttons-container'>
                <button
                  className={remark ? 'mark-complete-btn' :'disabled'}
                  onClick={(e) => handleButtonClick(e,TASK_STATUS.COMPLETED)}
                >
                  {"Mark Completed"}
                </button>
                {task?.task_status !== TASK_STATUS.IN_PROGRESS && <button
                  className={task?.task_status === TASK_STATUS.IN_PROGRESS ? 'disabled' : 'in-progress-button'}
                  onClick={(e) => handleButtonClick(e , TASK_STATUS.IN_PROGRESS)}
                >
                  {"Mark In Progress"}
                </button>}
              </div>
            </div>
          )}
        </div>
      </StyledCsrTaskModal>
      </Spin>
    </Modal>
  );
};

export default CsrTaskModal;
