import React, { useState } from "react";
import CsrTaskModal from "../TaskModal";
import { TASK_STATUS } from "../constant";
import { StyledCard } from "./StyledTaskCard";
import { getFormattedDate } from "../../../utils/timeUtils";

const TaskCard = ({ task ,userRole }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const truncateText = (text ='', length) => {
    return text?.length > length ? text?.substring(0, length) + "..." : text;
  };

  const isNonCompleted = task?.task_status === TASK_STATUS.PENDING || task?.task_status === TASK_STATUS.IN_PROGRESS ;

  return (
    <>
      <StyledCard onClick={showModal}>
        <h3>{truncateText(task?.task_detail?.title, 20)}</h3>
        <p className="tags">
              Tags:{" "}
              {task?.task_detail?.tags
                .join(", ")
                .replaceAll("_", " ")
                .toLowerCase()}
            </p>
        <div className="avatar">
          <p className="creator-name">{task?.created_by?.first_name}</p>
        </div>

        {isNonCompleted && (
          <div className="due-date-wrapper">
            <p className="due-date">
              Due Date:{" "}
              {getFormattedDate(task?.task_detail?.due_date)}
            </p>
          </div>
        )}
      </StyledCard>
      <CsrTaskModal
        isVisible={isModalVisible}
        onCancel={handleCancel}
        task={task}
        userRole={userRole}
      />
    </>
  );
};




export default TaskCard;
