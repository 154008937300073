import React from "react";
import { Tooltip } from "@material-ui/core";
import CopyClipboard from "../CopyClipboard";
import styled from "styled-components";
import { dim } from "../../styles/theme";

const DataGridCustomStyle = styled.div`
  width: ${(props) => props.cellWidth};
  position: relative;
  white-space: nowrap;
  .strip-color {
    background-color: ${(props) => props.bgColor ? props.bgColor : 'red'};
    height: 100%;
    position: absolute;
    left: -${dim._10px};
    width: ${dim._10px};
  }
  &:hover .clipboard {
    display: flex;
    background-color: #f5f5f5;
    padding: 0px 10px 2px 10px;
  }
`;

const DataGridCustomTooltip = ({
  showExpiredBg = false,
  showCCB = true,
  dataVal='-',
  cellWidth = "200px",
  toTrim = false,
  bgColor
}) => {
  return (
    <DataGridCustomStyle cellWidth={cellWidth} bgColor={bgColor}>
      {showExpiredBg && <div className="strip-color"></div>}
      {showCCB && <CopyClipboard text={dataVal} />}
      <Tooltip title={dataVal || ""}>
        <span className="userName">
          {toTrim ? dataVal.slice(0, 20) + "..." : dataVal}
        </span>
      </Tooltip>
    </DataGridCustomStyle>
  );
};

export default DataGridCustomTooltip;
