import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router";
import { UpdateStatusRentAgreement } from "../../../../Services/RentAgriment";
import {
  ClientDetails,
  StyledSuperClientProfile,
} from "../../../SuperClientProfile/SuperSaverProfile.styled";
import { StyledRentDetails } from "./RentDetails.styled";

const RentDetails = () => {
  const state = useLocation();
  const [status, setStatus] = useState(state.state.item.status);
  const statusMap = [
    { label: "Pending", value: "PENDING" },
    { label: "In Progress", value: "INPROGRESS" },
    { label: "Completed", value: "COMPLETED" },
  ];

  const payloadBody = {
    document_link: {
      links: [],
    },
  };

  const clientArray = [
    {
      placeholder: "Name",
      value: state.state.item.name,
    },
    {
      placeholder: "Mobile No",
      value: state.state.item.mobile,
    },
    {
      placeholder: "Email",
      value: state.state.item.email,
    },
    {
      placeholder: "Aadhar Card",
      value: state.state.item.details.aadharcard,
    },
    {
      placeholder: "Monthly rent",
      value: state.state.item.details.monthly_rent,
    },
    {
      placeholder: "Pan card",
      value: state.state.item.details.pandcard,
    },
    {
      placeholder: "Rented property address",
      value: state.state.item.details.rented_property_address,
    },
    {
      placeholder: "Rented property type",
      value: state?.state?.item?.details?.propertyType,
    },
    {
      placeholder: "Security_deposit",
      value: state.state.item.details.security_deposit,
    },
    {
      placeholder: "Land lord Name",
      value: state.state.item.land_lord_details.name,
    },
    {
      placeholder: "Land lord Pancard",
      value: state.state.item.land_lord_details.pancard,
    },

    {
      placeholder: "Duration",
      value: state.state.item.duration,
    },
  ];
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => toast.success("Copied to clipboard"))
      .catch((error) => toast.error("Failed to copy: " + error));
  };

  const handleChange = (e) => {
    setStatus(e?.target?.value)
    UpdateStatusRentAgreement(
      state.state.item.externalId,
      e.target.value,
      payloadBody
    );
  }
  return (
    <StyledRentDetails>
      <StyledSuperClientProfile>
        <div className="ClientWrap">
          {clientArray.map((data, i) => (
            <div className="clientBox">
              <div className="ClientHed">{data.placeholder}</div>
              <ClientDetails className="ClientDetails">
                <div className="data">
                  <Tooltip title={data.value}>
                    <span>
                      {data.value && data.value.length > 24
                        ? data.value.slice(0, 24) + " ..."
                        : data.value}
                    </span>
                  </Tooltip>
                </div>
                <img
                  className="clipboard"
                  onClick={() => copyToClipboard(data.value)}
                  src={
                    process.env.PUBLIC_URL + "/assets/icons/copy-file-icon.svg"
                  }
                  alt="copy"
                />
              </ClientDetails>
            </div>
          ))}
          <div className="clientox">
            <ClientDetails className="" activate={true}>
              <div
                className=""
                style={{ margin: " 0px 34px ", padding: "24px 24px 0" }}
              >
                <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={status}
                    onChange={(e) => handleChange(e)}
                    label="Advisor"
                    MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {statusMap.map((d, i) => (
                      <MenuItem value={d.value}>{d.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </ClientDetails>
          </div>{" "}
        </div>
      </StyledSuperClientProfile>
    </StyledRentDetails>
  );
};

export default RentDetails;
