import React, { useState } from "react";
import DropDownComponent from "../../container/ConsultationExperience/components/DropdownComponet";
import { StyledActionFilter } from "./ActionFilter.styled";
import { modifyMetaData } from "../../utils/modifyMetaData";
import { useSelector } from "react-redux";

const ActionFilter = ({ handleSelect, actionSelected }) => {
  const actions = useSelector((state) => state.cmsReducer);
  const data = modifyMetaData(actions?.actionable_category) || [];

  return (
    <StyledActionFilter className="dropdownBox">
      <label htmlFor="">Select Action Type :</label>{" "}
      <DropDownComponent
        dataOptions={data}
        className="dropdown"
        defaultValue={"Select Action"}
        handleDropDownChange={(value) => {
          handleSelect(value);
        }}
        type={actionSelected}
      />
    </StyledActionFilter>
  );
};

export default ActionFilter;
