import styled from "styled-components";
import { dim } from "../../../styles/theme";
import { theme } from "../../../styles/theme";

export const StyledCsrTaskModal = styled.div`

.modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${dim._20px};
  max-height: 60vh;
  overflow-y: scroll;
}

.task-info {
  margin-bottom: ${dim._20px};
}

h3 {
  font-size: ${dim._24px};
  margin-bottom: ${dim._10px};
}

p {
  margin: ${dim._6px} 0;
  font-size: ${dim._14px};
  line-height: 1.5;
  color: ${theme.color.grey_160};
}

.due-date-wrapper {
  margin-top: ${dim._10px};
}

.due-date {
  color:${theme.color.orange_25};
  font-weight: 600;
}

.task-status {
  color: ${theme.color.orange_25};
  font-weight: 600;
}

.tags {
  font-style: italic;
  font-size: ${dim._12px};
  color:  ${theme.color.grey_155};
  text-transform: capitalize;
}

.status {
  font-size: ${dim._12px};
  color:${theme.color.grey_160};
}

.mark-complete-btn {
  padding: ${dim._10px} ${dim._20px};
  background-color: ${theme.color.blue_90};
  border: none;
  color: ${theme.color.white_0};
  border-radius: ${dim._4px};
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.in-progress-button{
  padding: ${dim._10px} ${dim._20px};
  background-color: ${theme.color.yellow_0};
  border: none;
  color: ${theme.color.white_0};
  border-radius: ${dim._4px};
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.buttons-container{
  display: flex;
  gap: ${dim._20px};
  align-items: center; 
}

.mark-complete-btn:hover {
  background-color: #40a9ff;
}

.disabled{
  background-color: lightgray;
  pointer-events: none;
  cursor: not-allowed;
  padding: ${dim._10px} ${dim._20px};
  border: none;
  color: ${theme.color.white_0};
  border-radius: ${dim._4px};
  font-weight: 600;
}
.remarks{
  font-style: italic;
  font-size: ${dim._12px};
  color:${theme.color.grey_155};
  text-transform: capitalize;
}
`