import styled from "styled-components";
import { dim } from "../../styles/theme";

export const StyledCsrTaskCreator = styled.div`
  height: 100%;
  width: ${({ width }) => width || "100%"};
  padding-top: ${dim._16px};

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #fff;
    z-index: 10;
    margin:-4px;
  }
  .heading-items{
    display: flex;
    align-items: center;
    gap:${dim._16px};
  }
  .csr-heading{
    font-size: ${dim._16px};
    font-weight: 600;
  }

  .header-buttons {
    display: flex;
    justify-content: space-evenly;
    gap: ${dim._10px};
    align-items: center;
    background-color: #fff;
    z-index: 10;
  }
  .ant-collapse {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    border: 1px solid white !important;
  }

  .ant-collapse-item-active .ant-collapse-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .ant-collapse-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    border-radius: 2px !important;
  }

  .scrollable-content {
    max-height: ${dim._scale(400)};
    overflow-y: auto;
    padding: ${dim._10px};
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .create-btn {
    border: 1px solid #1877ff;
    color: #1877ff;
  }

  @media (max-height: 700px) {
    .scrollable-content {
      max-height: ${dim._scale(180)};
      padding: ${dim._4px};
    }
  }
`;
